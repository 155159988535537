<template>
  <div class="payment-form">
    <div class="amount-selection">
      <div class="select-amount-box">
        <div class="amount-item" v-for="(item, index) in selectAmountList" :key="index">
          <button @click="selectAmount(item.Money)" :class="{ selected: selectedAmount === item.Money }">
            ${{ item.Money }}<br>={{ item.balance }} game coins
          </button>
        </div>
      </div>
    </div>
    <div class="notice-box" v-if="notice">
      <div class="notice-title">
        <img src="@/assets/wow.png" alt="Good News" class="title-img" />
        GOOD NEWS
      </div>
      <div>
        {{ notice }}
      </div>
    </div>
    <div class="custom-amount" v-if="showCustomAmount">
      <label for="custom-amount">Enter Amount:</label>
      <div>
        <input type="number" id="custom-amount" v-model.number="customAmount" @input="validateInput" readonly />
      </div>
    </div>
    <div class="ton-box">
      <div  v-if="rawAddress" style="width: 100%; display: flex;flex-direction: column">
<!--        <span>User-friendly address: {{ userFriendlyAddress }}</span>-->
        <div>Raw address: {{ rawAddress }}</div>
        <button @click="copyRawAddress"> copy</button>
      </div>
      <div>
        <button @click="decodeMessage">解码消息</button>
        <p v-if="decodedMessage">解码结果: {{ decodedMessage }}</p>
      </div>
      <TonConnectButton class="connect-button" />
            <button @click="sendTransaction">测试交易</button>
    </div>

      <button v-if="wallet" class="next-button" @click="nextStep">Next</button>


    <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
      <template v-slot:body>
        {{ initErrorMsg }}
      </template>
    </RemindPP>
  </div>
</template>

<script>
import { TonConnectButton, useTonConnectUI, useTonAddress ,useTonWallet } from '@townsquarelabs/ui-vue';
import { onMounted, ref } from 'vue';
import Request from '@/utils/request';
import { event } from 'vue-gtag';
// import { Address } from '@ton/ton'
import {useClipboard} from "@vueuse/core";
import TonWeb from "tonweb";


export default {
  components: {
    TonConnectButton,
  },
  setup() {
    // const tonConnectUI = useTonConnectUI();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const selectedAmount = ref(1);
    const customAmount = ref(1);
    const isShowErrorPP = ref(false);
    const isOrderInitError = ref(false);
    const initErrorMsg = ref('');
    const selectAmountList = ref([]);
    const notice = ref('');
    const showCustomAmount = ref(false);
    const orderInitCalled = ref(false);
    const decodedMessage = ref(null);
    // const payload = "te6cckEBAQEAIgAAPwAAAABAX14QBUT04gQ29ubmVjdCAyIOaVmeeoi++8gYNwShgg==";

    const decodeMessage = async () => {
      try {
        // const tonweb = new TonWeb();
       /* const boc = TonWeb.utils.base64ToBytes("te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==");
        const cell = TonWeb.boc.Cell.oneFromBoc(boc).getRepr();
        // const boc = atob("te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==");
        console.log("Decoded Message:", cell.toString());
        const replaceBoc = cell.replace(new RegExp("Hello!", 'g'), "long fei ni hao");
        console.log("replaceBoc:", replaceBoc.toString());
        const encodedText = btoa(replaceBoc); // 将输入文本编码为 Base64
        console.log("encodedText:", encodedText);*/
/*        let a = new TonWeb.boc.Cell();
        a.bits.writeUint(0, 32);
        a.bits.writeString(" Hello! ");
        a.bits.writeCoins("100000000");

        let payload = TonWeb.utils.bytesToBase64(await a.toBoc());
        console.log("payload:", payload);*/
    /*  const payload= "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g=="
        const bocBytes = TonWeb.utils.base64ToBytes(payload);

        // 2. 使用 fromBoc 将字节数组反序列化为 Cell 对象
        const cells = TonWeb.boc.Cell.fromBoc(bocBytes);
        const cell = cells[0]; // 获取第一个 Cell 对象
        cell.bits.writeString("long fei")
        // 假设你知道写入数据的格式，手动解析
        const uint32Buffer = cell.bits.array.slice(0, 4); // 读取前4字节 (32位无符号整数)
        const someUint = new DataView(uint32Buffer.buffer).getUint32(0);

        // 假设字符串从第5个字节开始
        const stringBytes = cell.bits.array.slice(4); // 读取字符串字节
        const decoder = new TextDecoder(); // 使用 TextDecoder 解码为字符串
        const message = decoder.decode(stringBytes);

        console.log('Decoded Uint:', someUint);
        console.log('Decoded message:', message);

        console.log('Decoded message:', message);*/

        // 1. 使用 fromBoc 将字节数组反序列化为 Cell 对象
        const payload = "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==";
        const bocBytes = TonWeb.utils.base64ToBytes(payload);

// 1. 使用 fromBoc 将字节数组反序列化为 Cell 对象
        const cells = TonWeb.boc.Cell.fromBoc(bocBytes);
        const cell = cells[0]; // 获取第一个 Cell 对象

// 2. 读取现有的 bits 和 refs
        const originalBits = cell.bits; // 保存原有的 bit 数据
        const originalRefs = cell.refs; // 保存原有的 refs (引用)

        console.log('Original bits:', originalBits.toString());
        console.log('Original refs:', originalRefs);

// 3. 创建一个新的 Cell，并保留原有的所有内容
        let newCell = new TonWeb.boc.Cell();

// 4. 将原有的 bits 和 refs 写入到新的 Cell 中
        newCell.bits.writeBitString(originalBits); // 写入原有的 bit 数据
        originalRefs.forEach(ref => newCell.refs.push(ref)); // 保持原有的引用结构

// 5. 在原有的基础上添加新的字符串
        newCell.bits.writeString("你好"); // 添加新信息 "你好"

// 6. 将新的 Cell 转换回 BOC 格式
        const newPayload = TonWeb.utils.bytesToBase64(await newCell.toBoc());

        console.log('New payload:', newPayload);


      } catch (error) {
        console.error("解析 BOC 数据失败:", error);
      }
    };


    const sendTransaction = async () => {
     /* const body = beginCell()
          .storeUint(0, 32)   // 写入32个零位以表示后面将跟随文本评论
          // .storeCoins(100000000)
          .storeStringTail("") // 写下我们的文本评论
          // .storeCoins(10000000)
          .endCell();*/
      // const destinationAddress = Address.parse('UQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBsOf');

 /*     const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail('Hello!')
          .endCell();*/

   /*   const body = beginCell()
          // .storeUint(0, 64)// opcode for jetton transfer
          // .storeRef(forwardPayload)
          .endCell();
           let data = body.toBoc().toString("base64");
          */

      let a = new TonWeb.boc.Cell();
      a.bits.writeUint(0, 32);
      a.bits.writeString(" Hello! ");
      a.bits.writeCoins("100000000");

      let payload = TonWeb.utils.bytesToBase64(await a.toBoc());




   /*   const body = beginCell()
          .storeUint(0xf8a7ea5, 32)                 // jetton transfer op code
          // .storeUint(0, 64)                         // query_id:uint64
          // .storeCoins(toNano("0.001"))              // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - USDT, 9 - default). Function toNano use decimals = 9 (remember it)
          // .storeAddress(Address.parse('UQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBsOf'))  // destination:MsgAddress
          // .storeAddress(Address.parse('UQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBsOf'))  // response_destination:MsgAddress
          // .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
          .storeCoins(1000000)                 // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
          // .storeUint(0,1)                           // forward_payload:(Either Cell ^Cell)
          .endCell();*/
      console.log( payload)
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: [
          {
            address: "0:9084d3d304e4df8e9cc58b1cf9f8d8c944c415d7fcc5cafcc69e07736aa9ac06",
            amount: "1000000",
            // payload: "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==",
            // payload: body.toBoc().toString("base64") ,
            payload:"te6ccsEBAQEAEgAAACAAAAAASGVsbG8h5L2g5aW9AdOcCA==",
            // payload: "te6ccsEBAQEAHQAAADYAAAAAVE9OIENvbm5lY3QgMiB0dXRvcmlhbCFdy+mw",
            // stateInit: "te6ccsEBBAEAUwAABRJJAgE0AQMBFP8A9KQT9LzyyAsCAGrTMAGCCGlJILmRMODQ0wMx+kAwi0ZG9nZYcCCAGMjLBVAEzxaARfoCE8tqEssfAc8WyXP7AAAQAAABhltsPJ+MirEd"
          },
        ],
      };

      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result);
      } catch (error) {
        console.log(error);
      }

  /*    "in_msg": {
        "@type": "raw.message",
            "source": "EQC0mCQpo6-V3ne86ZRKCxzov8apq9k3JyKlZBLHXQ2-4b5a",
            "destination": "EQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBp5a",
            "value": "1000000",
            "fwd_fee": "266669",
            "ihr_fee": "0",
            "created_lt": "49265032000002",
            "body_hash": "kWE++bxfHvQryzunm/ODjJLAmsoblNEFya1hiEw1+Fs=",
            "msg_data": {
          "@type": "msg.dataRaw",
              "body": "te6cckEBAQEAIgAAPwAAAABAX14QBUT04gQ29ubmVjdCAyIOaVmeeoi++8gYNwShgg==",
              "init_state": ""
        },
        "message": "AAAAAEBfXhAFRPTiBDb25uZWN0IDIg5pWZ56iL77yBA=\n"
      },*/
  /*    "in_msg": {
        "@type": "raw.message",
            "source": "EQC0mCQpo6-V3ne86ZRKCxzov8apq9k3JyKlZBLHXQ2-4b5a",
            "destination": "EQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBp5a",
            "value": "1000000",
            "fwd_fee": "266669",
            "ihr_fee": "0",
            "created_lt": "49264211000002",
            "body_hash": "AgUSCeJWkWci12WETOBh3GrRjzWRRlsMYMcD6M+MMl4=",
            "msg_data": {
          "@type": "msg.dataText",
              "text": "SGVsbG8sIFRPTiE="
        },
        "message": "Hello, TON!"
      },
      "te6cckEBBQEA4gAB5YgBaTBIU0dfK7zvedMolBY50X+NU1eybk5FSsgljrobfcIDm0s7c///+Is3VSUgAAAAtFiIsdWndnkDpwJqO9Gc9eYtakBfjE+/DJ6D1Et8MEOBiM+I2WYWgk0qzi6xJeg74uilTTg9sGXMJNKNx3mPQBkBAgoOw8htAwIDAAABl0IASEJp6YJyb8dOYsWOfPxsZKJiCuv+YuV+Y08DubVU1gMYehIAAAAAAAAAAAAAAAAAAAAAAABAX14QBWUG9qVEZiVlJvcDRNUndlwEACBWUG9qVEZiVlJvcDRNUndlq7rbiw=="
      */
    };

    const validateInput = (event) => {
      const value = event.target.value;
      customAmount.value = value.replace(/[^\d]/g, '');
      selectedAmount.value = 0;
    };

    const selectAmount = (amount) => {
      selectedAmount.value = amount;
      customAmount.value = amount;
      showCustomAmount.value = false;
    };

    const nextStep = async () => {
      if (customAmount.value < 1) {
        return;
      }

      try {
        const data = await Request({
          url: '/api/order/ton',
          method: 'post',
          data: { payMoney: parseInt(customAmount.value) },
        });

        console.log(data);
        event('pay', { method: 'ton_pay' });
        // const result = await tonConnectUI.sendTransaction(data);
        // console.log(result)

        let a = new TonWeb.boc.Cell();
        a.bits.writeUint(0, 32);
        a.bits.writeCoins(100000000);
        a.bits.writeString("TON Connect 2 教程！");

      /*  const body = beginCell()
            .storeUint(0xf8a7ea5, 32) // op transfer
            .storeUint(0, 64) // queryId
            .storeCoins(toNano(depositAmount)) // deposit_amount
            .storeAddress(
                Address.parse("receiver address"),
            ) // receiver address
            .storeAddress(Address.parse("exceed fee receiver")) //response_adress - address nhận phí GD thừa
            .storeMaybeRef(null) // custom_payload
            .storeCoins(toNano("0.05")) // forward_ton_amount
            .storeMaybeRef(beginCell().storeStringTail("something").endCell()) // forward_payload_amount if receiver is a smart contract
            .endCell();*/

        let payload = TonWeb.utils.bytesToBase64(await a.toBoc());
        // let payload =  body.toBoc().toString("base64");
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 120,
          messages: [
            {
              address: "0:9084d3d304e4df8e9cc58b1cf9f8d8c944c415d7fcc5cafcc69e07736aa9ac06",
              amount: "1000000",
              // payload: "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==",
              payload: payload ,
            },
          ],
        };

        try {
          const result = await tonConnectUI.sendTransaction(transaction);
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        isShowErrorPP.value = true;
        initErrorMsg.value = error.message;
      }
    };

    const orderInit = async () => {
      try {
        const data = await Request({
          url: '/api/order/init',
          method: 'post',
          data: {},
        });

        selectAmountList.value = data.selectAmount;
        if (data.selectAmount[0]?.Money) {
          selectedAmount.value = data.selectAmount[0].Money;
          customAmount.value = data.selectAmount[0].Money;
        }
        notice.value = data.notice;
        if (data.status === 2) {
          isOrderInitError.value = true;
          isShowErrorPP.value = true;
          initErrorMsg.value = data.msg;
        } else {
          isOrderInitError.value = false;
          isShowErrorPP.value = false;
          initErrorMsg.value = '';
        }
      } catch (error) {
        isShowErrorPP.value = true;
        initErrorMsg.value = error.message;
      }
    };

    const onClickBack = () => {
      isShowErrorPP.value = false;
    };
    const copyRawAddress = () => {
      if (window.clipboardData) {
        window.clipboardData.setData('text', rawAddress.value);
      }else {
        const {copy } = useClipboard({ legacy: true })
        copy( rawAddress.value)
      }
      alert('Copy Success');
    };

    onMounted(() => {
      if (!orderInitCalled.value) {
        orderInitCalled.value = true;
        orderInit();
      }
    });

    return {
      // sendTransaction,
      userFriendlyAddress,
      rawAddress,
      selectedAmount,
      customAmount,
      isShowErrorPP,
      isOrderInitError,
      initErrorMsg,
      selectAmountList,
      notice,
      showCustomAmount,
      validateInput,
      nextStep,
      orderInit,
      selectAmount,
      onClickBack,
      wallet,
      copyRawAddress,
      sendTransaction,
      decodedMessage,
      decodeMessage
    };
  },
};
</script>


<style scoped>
.connect-button {
  float: right;
  margin: 10px;

}

.ton-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  color: #8F8F8F;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;

}


.amount-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #282828;
  margin-bottom: 50px;
  padding: 20px;
}

.amount-selection button {
  background-color: #3A3A3A;
  border: none;
  color: #D7D7D7;
  height: 125px;
  cursor: pointer;
  flex: 1;
  border-radius: 10px;
  font-size: 25px;
  line-height: 50px;
}

.amount-selection button.selected {
  border: 2px solid #15BB4F;
  color: #15BB4F;
}

.custom-amount {
  width: 100%;
  margin-bottom: 20px;
}

.custom-amount label {
  display: block;
  margin: 22px 0 22px 30px;
}

.custom-amount div {
  display: flex;
  width: 100%;
  height: 80px;
  background: #3A3A3A;
}

.custom-amount input {
  background: #3A3A3A;
  border: none;
  flex: 1;
  height: 100%;
  font-size: 24px;
  padding: 0 30px;
  color: white;
}

.next-button {
  background-color: #15BB4F;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 30px;
  color: white;
  font-size: 40px;
  height: 80px;
}

.select-amount-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.amount-item {
  display: flex;
}

.notice-box {
  background: #282828;
  margin-bottom: 50px;
  padding: 30px;
  color: #D7D7D7;
  font-size: 25px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}
 ::v-deep .tc-root button {
  outline: none;
  width: 700px;
  height: 600px !important;
  padding: 50px;
}
</style>
