<template>
  <view>
      <view @click="startListening">开始 </view>
  </view>
</template>


<script>
import TonWeb from "tonweb";
import {AccountSubscription} from "@/utils/account/AccountSubscription";


export default {
  components: {

  },
  setup() {



// Use toncenter.com as HTTP API endpoint to interact with TON blockchain.
// You can get HTTP API key at https://toncenter.com
// You can run your own HTTP API instance https://github.com/toncenter/ton-http-api
    const tonweb = new TonWeb(new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC', {apiKey: "c7ec154f4dcabda448cac5a3ef56f01e7cebe7abdbee531c1046ec8cc2736319"}))

    // const MY_WALLET_ADDRESS = 'UQB7AhB4fP7SWtnfnIMcVUkwIgVLKqijlcpjNEPUVontys5I';
    const startListening = () => {
      const onTransaction = async (tx) => {
        // If incoming message source address is defined and no outgoing messages - this is incoming Toncoins.
        // ATTENTION: ALWAYS CHECK THAT THERE WERE NO OUTGOING MESSAGES.
        // It is important to check that Toncoins did not bounce back in case of an error.

        if (tx.in_msg.source && tx.out_msgs.length === 0) {

          if (tx.in_msg.msg_data && tx.in_msg.msg_data['@type'] !== 'msg.dataText') { // no text comment
            return;
          }

          const value = tx.in_msg.value; // amount in nano-Toncoins (1 Toncoin = 1e9 nano-Toncoins)
          const senderAddress = tx.in_msg.source; // sender address
          const payload = tx.in_msg.message; // transfer text comment (in our case, the user should send the UUID as a text comment)

          // here you find the payment in your database by UUID,
          // check that the payment has not been processed yet and the amount matches,
          // save to the database that this payment has been processed.

          console.log(`Receive ${TonWeb.utils.fromNano(value)} TON from ${senderAddress} with comment "${payload}"`);
        }
      }

      const accountSubscription = new AccountSubscription(tonweb, "UQCQhNPTBOTfjpzFixz5-NjJRMQV1_zFyvzGngdzaqmsBsOf", 0, onTransaction);
      accountSubscription.start();
    };

    return {

      startListening,

    };
  },
};
</script>

<style scoped>

</style>
