<template>
  <div class="payment-form">
    <div class="amount-selection">
      <div class="select-amount-box">
        <div class="amount-item" v-for="(item, index) in selectAmountList" :key="index">
          <button @click="selectAmount(item.Money)" :class="{ selected: selectedAmount === item.Money}">
            USDT-TRC20<br>${{ item.Money }}<br>={{ item.balance }} game coins
          </button>
        </div>
      </div>
    </div>
    <div class="notice-box" v-if="notice">
      <div class="notice-title">
        <img src="@/assets/wow.png" alt="Good News" class="title-img"/>
        GOOD NEWS
      </div>
      <div>
        {{ notice }}
      </div>
    </div>
    <div class="custom-amount" v-if="showCustomAmount">
      <label for="custom-amount">Enter Amount:</label>
      <div>
        <input type="number" id="custom-amount" v-model.number="customAmount" @input="validateInput" readonly/>
      </div>
    </div>
    <button class="next-button" @click="nextStep">Next</button>
    <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
      <template v-slot:body>
        {{ initErrorMsg }}
      </template>
    </RemindPP>
  </div>
</template>

<script>
import Request from "@/utils/request";
import RemindPP from "@/components/RemindPP.vue";
import {event} from "vue-gtag";
// import userService from "@/utils/userService";
// import {event} from "vue-gtag";


export default {
  components: { RemindPP, },
  data() {
    return {
      selectedAmount: 1,
      customAmount: 1,
      isShowErrorPP: false,
      isOrderInitError: false,
      initErrorMsg: "",
      selectAmountList: [],
      notice: 'Please select USDT-TRC20 as the payment method.',
      showCustomAmount: false,
      orderInitCalled: false // 添加一个状态变量
    };
  },
  created() {
    if (!this.orderInitCalled) {
      this.orderInitCalled = true;
      this.orderInit();
    }
  },
  methods: {
    validateInput(event) {
      const value = event.target.value;
      this.customAmount = value.replace(/[^\d]/g, '');
      this.selectedAmount = 0;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
      this.showCustomAmount = false;
    },
    nextStep() {
      if (this.customAmount < 1) {
        return;
      }

      Request({
        url: '/telegram/order/init',
        method: 'post',
        data: { payMoney: parseInt(this.customAmount) }
      }).then(data => {
        this.$router.push({path:'/qrCodePay',
          query: data})
        event('pay', { method: 'TronLink_pay' })
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },
    orderInit() {
      Request({
        url: '/api/order/init',
        method: 'post',
        data: {}
      }).then(data => {
        this.selectAmountList = data.selectAmount;
        if (data.selectAmount[0]&&data.selectAmount[0].Money) {
          this.selectedAmount=data.selectAmount[0].Money
          this.customAmount=data.selectAmount[0].Money
        }
        // this.notice = data.notice;
        if (data.status === 2) {
          this.isOrderInitError = true;
          this.isShowErrorPP = true;
          this.initErrorMsg = data.msg;
        } else {
          this.isOrderInitError = false;
          this.isShowErrorPP = false;
          this.initErrorMsg = '';
        }
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },
    onClickBack() {
      this.isShowErrorPP = false;
    }
  }
};
</script>

<style scoped>

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  color: #8F8F8F;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;

}


.amount-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #282828;
  margin-bottom: 50px;
  padding: 20px;
}

.amount-selection button {
  background-color: #3A3A3A;
  border: none;
  color: #D7D7D7;
  height: 150px;
  cursor: pointer;
  flex: 1;
  border-radius: 10px;
  font-size: 25px;
  line-height: 40px;
}

.amount-selection button.selected {
  border: 2px solid #15BB4F;
  color: #15BB4F;
}

.custom-amount {
  width: 100%;
  margin-bottom: 20px;
}

.custom-amount label {
  display: block;
  margin: 22px 0 22px 30px;
}

.custom-amount div {
  display: flex;
  width: 100%;
  height: 80px;
  background: #3A3A3A;
}

.custom-amount input {
  background: #3A3A3A;
  border: none;
  flex: 1;
  height: 100%;
  font-size: 24px;
  padding: 0 30px;
  color: white;
}

.next-button {
  background-color: #15BB4F;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 30px;
  color: white;
  font-size: 40px;
  height: 80px;
}

.select-amount-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.amount-item {
  display: flex;
}

.notice-box {
  background: #282828;
  margin-bottom: 50px;
  padding: 25px;
  color: #D7D7D7;
  font-size: 24px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}
</style>
