<template>
  <div class="container-box">

  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import {useRouter} from 'vue-router'; // Vue 3 路由钩子
import userService from "@/utils/userService";
export default {
  setup() {
    const router = useRouter();  // 获取路由实例
    const tgUserInfo = ref(null); // 使用 ref 来声明响应式数据

    onMounted(() => {
      if (window.Telegram.WebApp&&window.Telegram.WebApp.initDataUnsafe&&window.Telegram.WebApp.initDataUnsafe.user){
        const user = window.Telegram.WebApp.initDataUnsafe?.user;
        // const user={id:'0123456789',username:'ssy'}
        tgUserInfo.value = user;
        login(user)
      } else {
        // 跳转到登录页面或处理没有用户信息的情况
        // router.push({ path: '/login' });
      }
    });

    const login = (user) => {
      userService.uidLogin(user.id, user.username,(loginError, loginResponse) => {
        if (loginError) {
          console.log("==========login error", loginError);
          return;
        }
        console.log("==========login ", loginResponse);
        router.push({path: '/gameList'});  // 使用 router 实例跳转
      });
    };

    return {
      tgUserInfo
    };
  }
};
</script>

<style scoped>
.container-box {
  /* 样式定义 */
}
</style>

