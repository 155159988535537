<template>
  <div id="app">
    <TonConnectUIProvider :options="options">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" v-if="!$route.meta.keepAlive" />
      </router-view>
    </TonConnectUIProvider>
  </div>
</template>

<script>
import { TonConnectUIProvider } from '@townsquarelabs/ui-vue';
export default {
  name: "App",
  components: {
    TonConnectUIProvider,
  },
  setup() {
    // const options = {
    //   manifestUrl: "https://gametest.adsmob1.com/tonconnect-manifest.json",
    // };
    const options = {
      manifestUrl: "https://gametest.adsmob1.com/tonconnect-manifest.json",
      twaReturnUrl: 'https://t.me/testSkylander_bot/Happy_Happy_Game',
      actionsConfiguration: { twaReturnUrl: 'https://t.me/testSkylander_bot/Happy_Happy_Game' },
    };
    return {
      options,
    };
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #FFFFFF;
}

.action-buttons {
  padding: 0 30px 30px;
}
.action-buttons > button:not(:first-child) {
  margin-top: 20px;
}
.primary-button,
.plain-button {
  height: 70px;
  line-height: 70px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-weight: bold;
  font-size: 27px;
  display: block;
  width: 100%;
}

.primary-button {
  background: #3daadf;
  color: #fff;
}
.primary-button:active {
  background: #1781b5;
}

.plain-button {
  background: #f5f5f5;
  border: 2px solid #b079ee;
  box-sizing: border-box;
  color: #b079ee;
}
.plain-button:active {
  color: #7337b7;
  border: 2px solid #7337b7;
}
</style>
